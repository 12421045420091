import { UsePaginationProps } from '@mui/lab';
import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import React, { ReactNode, RefObject, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { twJoin, twMerge } from 'tailwind-merge';
import ConditionalParent from '../ConditionalParent';

export type TableBoxExtraHeader = {
  label?: string;
  colSpan: number;
};

export type TableBoxColumn = {
  id: string;
  label: string;
  /** @default table.variant === 'default' ? true : false */
  sortable?: boolean;
  /**
   * | | |
   * |-:|-:|
   * | **3xs** | 2rem |
   * | **2xs** | 4rem |
   * | **xs** | 6rem |
   * | **sm** | 8rem |
   * | **md** | 10rem |
   * | **lg** | 12rem |
   * | **xl** | 14rem |
   * | **2xl** | 16rem |
   * | **3xl** | 18rem |
   * | **4xl** | 20rem |
   */
  width: '3xs' | '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl';
  fixedWidth?: boolean;
  /** @default 'default' */
  lineStyle?: 'default' | 'compress';
  tooltip?: ReactNode;
  /** @default false */
  hide?: boolean;
};

export type TableBoxCell = {
  content: ReactNode;
  title?: string;
  /** @default 'default' */
  padding?: 'default' | 'none';
  className?: string;
  link?: string | null;
  onClick?: React.MouseEventHandler<HTMLTableCellElement>;
  /** When defined `content` is not wrapped in a `TableCell` node and is rendered as is */
  customCell?: true;
};

export type TableBoxRows = {
  key: string | number;
  defaultLink?: string | { pathname: string; state: any };
  deleted?: boolean;
  cols: (TableBoxCell | ReactNode)[];
};

type TableBoxProps<T> = {
  extraHeaders?: ReadonlyArray<TableBoxExtraHeader>;
  extraHeadersClasses?: string;
  extraHeadersCellClasses?: string;
  headersClasses?: string;
  headersCellClasses?: string;
  columns: ReadonlyArray<TableBoxColumn>;
  content: ReadonlyArray<T> | undefined;
  selectedRows?: ReadonlyArray<T>;
  rowDefinition: (row: T) => TableBoxRows;
  sorting?: {
    sortBy: string | undefined;
    sortOrder: 'asc' | 'desc' | undefined;
    handleSortChange: (col: string) => void;
  };
  fullWidth?: boolean;
  moreActionsCol?: boolean;
  pagination?: {
    totalEntries: number;
    page: number;
    pageSize: number;
    onChange: UsePaginationProps['onChange'];
    pageTopRef?: RefObject<HTMLElement>;
  };
  variant?: 'default' | 'residence';
};

export default function TableBox<T>({
  extraHeaders,
  extraHeadersClasses,
  extraHeadersCellClasses,
  headersClasses,
  headersCellClasses,
  columns,
  content,
  selectedRows,
  rowDefinition,
  sorting,
  variant = 'default',
  fullWidth = variant === 'residence',
  moreActionsCol,
  pagination,
}: Readonly<TableBoxProps<T>>) {
  const rows = useMemo(() => {
    return content?.map((row, index) => {
      const { key, defaultLink, deleted, cols } = rowDefinition(row);

      return (
        <TableRow key={key} className={deleted ? 'bg-neutral-400' : index % 2 === 0 ? 'bg-white' : 'bg-neutral-100'}>
          <>
            {cols.map((cell, index) => {
              const column = columns[index];
              const cellIsContent = !(typeof cell === 'object' && (cell as TableBoxCell)?.content !== undefined);
              const content = cellIsContent ? cell : (cell as TableBoxCell).content;
              const customCell = cellIsContent ? false : (cell as TableBoxCell).customCell;
              const className = cellIsContent ? undefined : (cell as TableBoxCell).className;
              const link = cellIsContent ? undefined : (cell as TableBoxCell).link;
              const onClick = cellIsContent ? undefined : (cell as TableBoxCell).onClick;
              const title = cellIsContent ? undefined : (cell as TableBoxCell).title;
              const padding = cellIsContent ? 'default' : (cell as TableBoxCell).padding;
              if (customCell) {
                return content;
              } else {
                const linkTo = link ?? defaultLink;
                const isLast = cols.length === index + 1;
                return (
                  !column.hide && (
                    <TableCell
                      key={column.id}
                      className={twMerge(linkTo || padding === 'none' ? '!p-0' : undefined, !linkTo && className)}
                      sx={{ fontSize: variant === 'residence' ? '14px !important' : undefined }}
                      onClick={onClick}
                    >
                      <ConditionalParent
                        on={linkTo && (moreActionsCol ? !isLast : true)}
                        parent={(children) =>
                          typeof linkTo === 'string' ? (
                            <Link className="text-inherit" to={linkTo}>
                              <div title={title} className={twMerge(padding !== 'none' && 'p-4', className)}>
                                {children}
                              </div>
                            </Link>
                          ) : (
                            <Link className="text-inherit" to={linkTo?.pathname as string} state={linkTo?.state}>
                              <div title={title} className={twMerge(padding !== 'none' && 'p-4', className)}>
                                {children}
                              </div>
                            </Link>
                          )
                        }
                      >
                        <ConditionalParent
                          on={column.lineStyle === 'compress'}
                          parent={(children) => (
                            <div className="h-1lh flex items-center">
                              <div className="max-h-2lh w-full line-clamp-2">{children}</div>
                            </div>
                          )}
                        >
                          <>{content}</>
                        </ConditionalParent>
                      </ConditionalParent>
                    </TableCell>
                  )
                );
              }
            })}
            {!fullWidth && !moreActionsCol && <TableCell />}
          </>
        </TableRow>
      );
    });
  }, [columns, content, selectedRows]);

  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead className={fullWidth ? 'min-w-full' : undefined}>
            {extraHeaders && (
              <TableRow className={extraHeadersClasses}>
                {extraHeaders.map((header, idx) =>
                  header.label ? (
                    <TableCell className={extraHeadersCellClasses} key={idx} colSpan={header.colSpan}>
                      {header.label}
                    </TableCell>
                  ) : (
                    <TableCell className={extraHeadersCellClasses} key={idx} colSpan={header.colSpan} />
                  )
                )}
              </TableRow>
            )}
            <TableRow
              className={headersClasses ? headersClasses : variant === 'default' ? 'bg-tableHeaderGreen/[0.25]' : ''}
            >
              {columns.map(
                (col, idx) =>
                  !col.hide && (
                    <Tooltip key={col.id} title={col.tooltip || ''}>
                      <TableCell
                        className={twJoin(
                          col.width === '3xs' && 'min-w-8 2xl:min-w-16',
                          col.width === '2xs' && 'min-w-16 2xl:min-w-24',
                          col.width === 'xs' && 'min-w-24 2xl:min-w-32',
                          col.width === 'sm' && 'min-w-32 2xl:min-w-40',
                          col.width === 'md' && 'min-w-40 2xl:min-w-48',
                          col.width === 'lg' && 'min-w-48 2xl:min-w-56',
                          col.width === 'xl' && 'min-w-56 2xl:min-w-64',
                          col.width === '2xl' && 'min-w-64 2xl:min-w-72',
                          col.width === '3xl' && 'min-w-72 2xl:min-w-80',
                          col.width === '4xl' && 'min-w-80 2xl:min-w-88',
                          col.fixedWidth && col.width === '3xs' && 'max-w-8 2xl:max-w-16',
                          col.fixedWidth && col.width === '2xs' && 'max-w-16 2xl:max-w-24',
                          col.fixedWidth && col.width === 'xs' && 'max-w-24 2xl:max-w-32',
                          col.fixedWidth && col.width === 'sm' && 'max-w-32 2xl:max-w-40',
                          col.fixedWidth && col.width === 'md' && 'max-w-40 2xl:max-w-48',
                          col.fixedWidth && col.width === 'lg' && 'max-w-48 2xl:max-w-56',
                          col.fixedWidth && col.width === 'xl' && 'max-w-56 2xl:max-w-64',
                          col.fixedWidth && col.width === '2xl' && 'max-w-64 2xl:max-w-72',
                          col.fixedWidth && col.width === '3xl' && 'max-w-72 2xl:max-w-80',
                          col.fixedWidth && col.width === '4xl' && 'max-w-80 2xl:max-w-88',
                          variant === 'default' &&
                            idx !== 0 &&
                            '!shadow-neutral-600/20 !shadow-[inset_1px_0px_0px_0px]',
                          headersCellClasses
                        )}
                        sx={{ fontSize: variant === 'residence' ? '14px !important' : undefined }}
                      >
                        <ConditionalParent
                          on={!col.tooltip && (col.sortable ?? variant === 'default')}
                          parent={(children) => <Tooltip title="Sortera">{children}</Tooltip>}
                        >
                          <TableSortLabel
                            active={sorting?.sortOrder && sorting?.sortBy === col.id}
                            direction={sorting?.sortOrder}
                            onClick={() => sorting?.handleSortChange(col.id)}
                            disabled={!(col.sortable ?? variant === 'default' ? true : false)}
                          >
                            {col.label}
                          </TableSortLabel>
                        </ConditionalParent>
                      </TableCell>
                    </Tooltip>
                  )
              )}

              <TableCell
                className={twJoin(
                  '!p-0 !shadow-neutral-600/20 !shadow-[inset_1px_0px_0px_0px]',
                  moreActionsCol && '!min-w-[3rem] !max-w-[3rem]',
                  !fullWidth && '!w-full',
                  headersCellClasses
                )}
              />
            </TableRow>
          </TableHead>
          <TableBody>{rows}</TableBody>
        </Table>
      </TableContainer>
      {pagination && (
        <div className="flex justify-center my-5">
          <Pagination
            variant="outlined"
            count={Math.ceil((pagination.totalEntries ?? 0) / pagination.pageSize)}
            page={pagination.page}
            showFirstButton
            showLastButton
            onChange={(...values) => {
              pagination.pageTopRef?.current?.scrollIntoView();

              pagination.onChange?.(...values);
            }}
          />
        </div>
      )}
    </div>
  );
}
